<template>
  <error-detail />
</template>

<script>
const ErrorDetail = () => import('@/components/Car/ErrorDetail')

export default {
  name: 'ErrorDetailView',
  components: {
    ErrorDetail
  }
}
</script>
